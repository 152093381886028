import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import {
  Card,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select as MUISelect,
} from "@mui/material";
import Box from "@mui/material/Box";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import API_ROUTES from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import { useFormData } from "../../../context/FormDataContext";
import { useGlobalData } from "../../../context/GlobalDataContext";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import MDInput from "../../../components/MDInput";
import TagsContext from "../../../context/TagsContext";
import AreaCodeContext from "../../../context/AreaCodeContext";
import BookingCard from "../../../examples/Cards/BookingCard";
import thumbnail from "../../../assets/images/thumbnail.jpg";
import Select from "react-select";
import FirstCard from "../../Reports/CampaignReports/FirstCard";
import SecondCard from "../../Reports/CampaignReports/SecondCard";
import ThirdCard from "../../Reports/CampaignReports/ThirdCard";
import FourthCard from "../../Reports/CampaignReports/FourthCard";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";

function GenerateCampaign() {
  const { formData, setFormData } = useFormData();
  const { state, dispatch } = useGlobalData();
  const [campaignName, setCampaignName] = useState(formData.campaignName || "");
  const [smsBody, setSmsBody] = useState(formData.smsBody || "");
  const [selectedImage, setSelectedImage] = useState(
    formData.selectedImage || null
  );
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (formData.campaignName) {
      setCampaignName(formData.campaignName);
    }
    if (formData.smsBody) {
      setSmsBody(formData.smsBody);
    }
    if (formData.selectedImage) {
      setSelectedImage(formData.selectedImage);
    }
  }, [formData]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const navigate = useNavigate();
  const group_id = localStorage.getItem("group_id");

  const handleSubmit = async () => {
    if (!campaignName) {
      toast.error("Campaign name is required");
      setError("Campaign name is required");
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("campaign_name", campaignName);
      formDataToSend.append("sms_body", smsBody);
      formDataToSend.append("group_id", group_id);
      if (imageFile) {
        formDataToSend.append("image", imageFile);
      }

      const response = await axios.post(
        API_ROUTES.DATA_SEND_CREATE_NEW_STEP_1,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        console.log("API Response:", response.data);
        setFormData({ campaignName, smsBody, selectedImage });
        dispatch({ type: "SET_STEP_ONE_DATA", payload: response.data.data });
        toast.success("Data submitted Successfully");

        // Reset the input fields
        setCampaignName("");
        setSmsBody("");
        setSelectedImage(null);
        setImageFile(null);

        // Refetch the tags and users data
        fetchTagsAndUsers();
      } else {
        toast.error("Error submitting form data");
        console.error("Error response:", response.data);
      }
    } catch (error) {
      toast.error("Error submitting form data");
      console.error("Error submitting form data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const {
    selectedTags,
    setSelectedTags,
    setAllTagsSelected,
    allTagsSelected,
    userData,
    setUserData,
  } = useContext(TagsContext);
  const { areaCodes } = useContext(AreaCodeContext);

  const [tags, setTags] = useState([{ value: 0, label: "All Tags" }]);
  const [total, setTotal] = useState("");

  const [selectedOption, setSelectedOption] = useState("option1");
  const [areaCode, setAreaCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchTagsAndUsers = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://crmstation.net/laravel/public/api/api_users_by_group?group_id=${group_id}`
      );
      const data = await response.json();

      if (data.status === "success") {
        const fetchedTags = data.tags.map((tag) => ({
          value: tag.id,
          label: tag.tag,
        }));
        setTags((prevTags) => [...prevTags, ...fetchedTags]);
        setTotal(data.total);
      }
    } catch (error) {
      console.error("Error fetching tags and users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTagsAndUsers();
  }, [group_id]);

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=ai_settings"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  const [campaignOptions, setCampaignOptions] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCampaignData();
  }, []);

  const fetchCampaignData = async () => {
    setLoading(true);
    setError(null);
    try {
      const groupId = localStorage.getItem("group_id");
      if (!groupId) {
        throw new Error("Group ID not found in localStorage");
      }

      const response = await axios.post(API_ROUTES.CAMPAING_REPORT_GET_DATA_COMPOSE, {
        group_id: groupId,
      });

      if (response.data.status === "success") {
        const campaignData = response.data.data.map((campaign) => ({
          id: campaign.id,
          name: campaign.campaign_name,
        }));
        setCampaignOptions(campaignData);
      } else {
        throw new Error("Failed to fetch campaign data");
      }
    } catch (error) {
      setError("Error fetching campaign data");
      console.error("Error fetching campaign data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateCampaign = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        API_ROUTES.API_GENERATE_CAMPAIGN,
        {
          group_id: parseInt(group_id, 10),
          campaign_id: selectedCampaign,
          area_code: selectedOption === "option1" ? "all_area_code" : areaCode,
          total_tags: selectedTags.map((tag) => tag.value),
        }
      );

      if (response.data.status === "success") {
        toast.success("Campaign generated successfully");
        // Reset the input fields
        setSelectedCampaign("");
        setSelectedTags([]);
        setSelectedOption("option1");
        setAreaCode("");
navigate(`/campaign_report_detail/${response.data.log_id}`)
        // Refetch the tags and users data
        fetchTagsAndUsers();
      } else {
        toast.error("Error generating campaign");
        console.error("Error response:", response.data);
      }
    } catch (error) {
      toast.error("Error generating campaign");
      console.error("Error generating campaign:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  
  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description}
                      price={video.price}
                      location={video.location}
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                     <h3>Video not found</h3>
                   </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>

        
        <MDBox mt={1.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <FirstCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <SecondCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <ThirdCard />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mt={3}>
                <FourthCard />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <Grid container justifyContent="center">
          <Grid item xs={12} md={8} lg={12} mt={4}>
            <Card>
              <MDTypography variant="h6" sx={{ mt: 2, ml: 3 }}>
                {translations.generate_message?.generate_message}
              </MDTypography>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="campaign-select-label">
                        {translations.generate_message?.select_message}
                      </InputLabel>
                      <MUISelect
                        labelId="campaign-select-label"
                        value={selectedCampaign}
                        onChange={(e) => setSelectedCampaign(e.target.value)}
                        disabled={loading}
                        style={{ height: "2.7rem" }}
                      >
                        {loading ? (
                          <MenuItem disabled>Loading...</MenuItem>
                        ) : error ? (
                          <MenuItem disabled>{error}</MenuItem>
                        ) : campaignOptions.length > 0 ? (
                          campaignOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No campaigns available</MenuItem>
                        )}
                      </MUISelect>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Select
                      isMulti
                      options={tags}
                      value={selectedTags}
                      onChange={handleTagChange}
                      placeholder="Select Tags"
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={3}
                    lg={12}
                    sx={{ display: "flex" }}
                  >
                    <Box display="flex" alignItems="center" mb={2}>
                      <MDTypography variant="h6" mr={2}>
                        {translations.generate_message?.area_code}
                      </MDTypography>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={selectedOption === "option1"}
                            onChange={() => setSelectedOption("option1")}
                            value="option1"
                            name="areaCodeOption"
                          />
                        }
                        label={translations.generate_message?.area_code_all}
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            checked={selectedOption === "option2"}
                            onChange={() => setSelectedOption("option2")}
                            value="option2"
                            name="areaCodeOption"
                          />
                        }
                        label={translations.generate_message?.select_area_code}
                      />
                    </Box>
                  </Grid>
                  {selectedOption === "option2" && (
                    <Grid item xs={12} sm={6} md={3} lg={12}>
                      <MDTypography variant="h6" fontWeight="bold">
                        Enter the area codes in the format 5511,5521,5531...
                        (with commas between them)
                      </MDTypography>
                      <MDInput
                        type="text"
                        // label="Enter Area Code"
                        fullWidth
                        value={areaCode}
                        onChange={(e) => setAreaCode(e.target.value)}
                      />
                    </Grid>
                  )}
                </Grid>
                <MDBox
                  mt={3}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MDButton
                    type="button"
                    variant="gradient"
                    style={{ backgroundColor: "#3f37c9", color: "white" }}
                    onClick={handleGenerateCampaign}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Generating..." : translations.generate_message?.generate_campaign_now}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default GenerateCampaign;

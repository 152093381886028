import React, { useState, useEffect } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";
import {
  Card,
  CardHeader,
  Button,
  CardContent,
  MenuItem,
  Select,
  Modal,
  Typography,
  Box,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import MDButton from "../../components/MDButton";
import EditIcon from "../../assets/images/edit-icon.png";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import API_ROUTES from "../../config/apiConfig";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { toast } from "react-toastify";

const Tags = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [modalOpen, setModalOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [previousTagId, setPreviousTagId] = useState(null);
  const [selectedTagId, setSelectedTagId] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const fetchData = async () => {
    const group_id = localStorage.getItem("group_id");

    try {
      const response = await axios.get(
        `${API_ROUTES.TAGS_FETCH_DATA}?group_id=${group_id}`
      );

      const tagsData = response.data.tags.map((tag) => ({
        id: tag.id,
        tag: tag.tag,
        total_users: tag.total_users,
        created_at: formatDate(tag.created_at),
        updated_at: formatDate(tag.updated_at),
        actions: (
          <div style={{ display: "flex", gap: "5px" }}>
              <IconButton
              aria-label="move"
              style={{ color: "#480ca8" }}
              onClick={() => handleOpenModal(tag.id)}
            >
              <ArrowForwardIcon />
            </IconButton>
            <NavLink
              to={`/edit_tag/${tag.id}`}
              style={{ textDecoration: "none" }}
            >
              <IconButton color="primary" aria-label="edit">
                <img src={EditIcon} alt="" style={{ width: "20px" }} />
              </IconButton>
            </NavLink>
          
            <IconButton aria-label="delete" style={{ color: "#f72585" }}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      }));

      setTags(response.data.tags);

      setTableData({
        columns: [
          { Header: "ID", accessor: "id", width: "20%" },
          { Header: "Tag", accessor: "tag", width: "30%" },
          {
            Header: translations.tag?.total_subs || "Total de Assinantes",
            accessor: "total_users",
            width: "30%",
          },
          {
            Header: translations.page_subscribers?.created_at || "Criado",
            accessor: "created_at",
            width: "25%",
          },
          {
            Header: translations.page_subscribers?.updated_at || "Atualizado",
            accessor: "updated_at",
            width: "25%",
          },
          {
            Header: translations.buttons_and_common_words?.actions || "Ações",
            accessor: "actions",
            width: "15%",
          },
        ],
        rows: tagsData,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleOpenModal = (tagId) => {
    setPreviousTagId(tagId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedTagId(null);
  };

  const handleMoveUsers = async () => {
    try {
      const group_id = localStorage.getItem("group_id");

      const response = await axios.post(API_ROUTES.API_MOVE_USER_TAG, {
        group_id,
        previous_tag_id: previousTagId,
        selected_tag_id: selectedTagId,
      });

      fetchData(); // Refresh the data
      handleCloseModal();

      toast.success(response.data.message || "Users moved successfully!");
    } catch (error) {
      console.error("Error moving users:", error);
      toast.error("Failed to move users. Please try again.");
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title={translations.buttons_and_common_words?.tags}
                action={
                  <NavLink
                    to={`/create-tags`}
                    style={{ textDecoration: "none" }}
                  >
                    <MDButton
                      variant="gradient"
                      style={{
                        backgroundColor: "#480ca8",
                        color: "white",
                        marginLeft: "8px",
                      }}
                      startIcon={<AddIcon />}
                    >
                      {translations.create_tag?.create_tag}
                    </MDButton>
                  </NavLink>
                }
              />
              <DataTable table={tableData} canSearch />
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Select
            fullWidth
            value={selectedTagId}
            onChange={(e) => setSelectedTagId(e.target.value)}
            displayEmpty
            sx={{ mt: 2, p:1 }}
          >
            <MenuItem value="" disabled>
              Select a {translations.edit_subscribers?.tags}
            </MenuItem>
            {tags
              .filter((tag) => tag.id !== previousTagId) // Exclude the previous tag
              .map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.tag}
                </MenuItem>
              ))}
          </Select>
          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <MDButton
              variant="gradient"
              color="black1"
              onClick={handleCloseModal}
              style={{ color: "black" }}
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
            <MDButton
              onClick={handleMoveUsers}
              variant="gradient"
              color="info"
              disabled={!selectedTagId}
            >
              {translations.tags?.move_users}
            </MDButton>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default Tags;

import PropTypes from "prop-types";
import MDBox from "../../../../../components/MDBox";
import MDTypography from "../../../../../components/MDTypography";
import { useContext } from "react";
import DataTable from "../../../../../examples/Tables/DataTable";
import TagsContext from "../../../../../context/TagsContext";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

function Profile({ formData }) {
  const { userData, deleteUser } = useContext(TagsContext);

  const columns = [
    { Header: "ID", accessor: "id" },
    { Header: "Name", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    { Header: "DOB", accessor: "dob" },
    { Header: "Company", accessor: "company" },
    { Header: "Lead", accessor: "lead" },
    { Header: "Created at", accessor: "created_at" },
    { Header: "Updated at", accessor: "updated_at" },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ cell }) => (
        <IconButton
          onClick={() => {
            console.log("This is row id ", cell.row.original.id);
            deleteUser(cell.row.original.id || cell.row.original.user_id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const transformedUserData = userData.map((user) => ({
    id: user.id ||  user.user_id,
    name: user.name,
    mobile: user.mobile,
    dob: new Date(`${user.dob_year}-${user.dob_month}-${user.dob_day}`).toLocaleDateString("en-GB"),
    company: user.company,
    lead: user.lead,
    created_at: new Date(user.created_at).toLocaleDateString("en-GB"),
    updated_at: new Date(user.updated_at).toLocaleDateString("en-GB"),
  }));

  const tableData = { columns, rows: transformedUserData };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Review
      </MDTypography>
      <MDBox mt={1.625}>
        <DataTable
          entriesPerPage={{
            defaultValue: 10,
            entries: ["5", "10", "15", "20", "25"],
          }}
          canSearch
          showTotalEntries
          table={tableData}
          pagination={{ variant: "gradient", color: "info" }}
          isSorted
          noEndBorder
        />
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for Profile
Profile.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Profile;

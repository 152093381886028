import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import DashboardLayout from "../../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../../examples/Footer";
import API_ROUTES from "../../../../config/apiConfig";
import DataTable from "../../../../examples/Tables/DataTable";
import Badge from "@mui/material/Badge";
import { green, orange } from "@mui/material/colors";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";

const BotsReportsDetails = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const { bots_id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const group_id = localStorage.getItem("group_id");

    if (group_id && bots_id) {
      axios
        .post(API_ROUTES.CAMPAIGN_REPORTS_COMPOSE_DETAIL, {
          group_id,
          campaign_id: bots_id,
        })
        .then((response) => {
          console.log("API Response:", response.data);
          setData(response.data.data); // Store the response data
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [bots_id]);

  const handleDelete = (id) => {
    const group_id = localStorage.getItem("group_id");

    if (group_id && id) {
      axios
        .post(API_ROUTES.CAMPAIGN_Detail_REPORTS, {
          group_id: parseInt(group_id, 10),
          sle_id: id,
        })
        .then((response) => {
          toast.success("Bot deleted successfully");
          console.log("Delete response:", response.data);
          // Filter out the deleted item from the data
          setData((prevData) => prevData.filter((item) => item.id !== id));
        })
        .catch((error) => {
          toast.error("Error deleting message");
          console.error("Error deleting data:", error);
        });
    }
  };

  const columns = [
    { Header: "ID", accessor: "id", width: "10%" },
    {
      Header: translations.bots_report?.message,
      accessor: "body",
      width: "30%",
      Cell: ({ value }) => {
        const maxLength = 100;
        return value.length > maxLength
          ? `${value.substring(0, maxLength)}...`
          : value;
      },
    },
    {
      Header: translations.your_public_import?.mobile,
      accessor: "cellphone",
      width: "15%",
    },
    {
      Header: translations.bots_reports?.birthday,
      accessor: "dob",
      width: "10%",
    },
    {
      Header: "Sent",
      accessor: "sent",
      width: "5%",
      Cell: ({ value }) =>
        value === 0 || value === 3 ? (
          <Badge
            badgeContent={value === 0 ? "Ready" : "Queue"}
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: value === 0 ? green[600] : orange[600],
                color: "white",
              },
            }}
          />
        ) : null,
    },
    {
      Header: translations.bots_reports?.date,
      accessor: "created_at",
      width: "10%",
    },
    {
      Header: translations.bots_reports?.type,
      accessor: "sle_type",
      width: "10%",
      Cell: ({ value }) => {
        const typeMapping = {
          2: {
            label: translations.bots_reports?.birthday || "Aniversário",
            color: "#3f37c9",
          },
          3: {
            label: translations.bots_reports?.welcome || "Boas-Vindas",
            color: "#60d3ca",
          },
          4: {
            label: translations.bots_report?.appointment || "Agendamento",
            color: "#b14bda",
          },
        };

        const badgeInfo = typeMapping[value] || {
          label: "Unknown",
          color: "grey",
        };

        return (
          <Badge
            badgeContent={badgeInfo.label}
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: badgeInfo.color,
                color: "white",
              },
            }}
          />
        );
      },
    },
    {
      Header: translations.buttons_and_common_words?.actions,
      accessor: "actions",
      width: "10%",
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <IconButton
            color="secondary"
            aria-label="delete"
            onClick={() => handleDelete(row.original.id)}
            style={{ color: "#f72585" }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const tableData = {
    columns,
    rows: data.map((item) => ({
      ...item,
      dob: item.dob ? new Date(item.dob).toLocaleDateString() : "N/A", // Format dob if available
      created_at: new Date(item.created_at).toLocaleDateString(), // Format created_at
    })),
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container>
          <Card sx={{ width: "100%" }}>
            <MDBox display="flex">
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                {translations.bot_reports?.bot_reports || "Relatórios de Bots"}
              </MDTypography>
            </MDBox>
            <MDBox p={2}>
              {loading ? (
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  {translations.campaign_reports?.loading}
                </MDTypography>
              ) : data.length > 0 ? (
                <DataTable table={tableData} canSearch />
              ) : (
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                  No data found
                </MDTypography>
              )}
            </MDBox>
          </Card>
        </Grid>
      </MDBox>
      <Footer />
      {/* <ToastContainer /> */}
    </DashboardLayout>
  );
};

export default BotsReportsDetails;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import BookingCard from "../../examples/Cards/BookingCard";
import thumbnail from "../../assets/images/thumbnail.jpg";
import Card from "@mui/material/Card";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";

const MultiChanel = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const handleOpenFlowBuilder = () => {
    const group_id = localStorage.getItem("name");

    if (!group_id) {
      toast.error("Name is missing from localStorage");
      return;
    }

    window.open(
      `https://insta.crmstation.net/home/login_newview?token=${group_id}`,
      "_blank"
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <MDBox mb={1.5}>
                <Card style={{height:"20rem"}}>
                  <MDBox p={3}>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                    </MDBox>
                    
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
      {/* <ToastContainer /> */}
    </DashboardLayout>
  );
};

export default MultiChanel;

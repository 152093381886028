export const BASE_URL = "https://crmstation.net/laravel/public";
export const FLOW_BUILDER_URL = "https://crmstation.net/flowbuilder/public";

const API_ROUTES = {
  LOGIN: `${BASE_URL}/api/api_login`,
  HOME_API_STATS: `${BASE_URL}/api/api_home_stats`,
  HOME_API_UPLOAD_FORM: `${BASE_URL}/api/api_addsetting`,
  HOME_API_UPLOAD_FORM_GET: `${BASE_URL}/api/api_settings`,
  BOTS_REPORTS_GET_DATA: `${BASE_URL}/api/api_bot_sleSmsReport`,
  SETTING_AI_GET_DATA_API_1ST: `${BASE_URL}/api/api_assistant`,
  SETTING_AI_SEND_DATA_API_1ST: `${BASE_URL}/api/api_assistant_add`,
  SETTING_AI_SEND_DATA_API_2ND: `${BASE_URL}/api/api_assistant_upload`,
  SETTING_AI_DELETE_DATA_API_2ND: `${BASE_URL}/api/api_assistant_file_delete`,
  SETTING_AI_SEND_DATA_3RD_CARD: `${BASE_URL}/api/api_assistant_send`,
  SETTING_AI_SEND_DATA_4RD_CARD: `${BASE_URL}/api/api_assistant_send_only`,
  SETTING_AI_SAVE_DATA_1RD_CARD: `${BASE_URL}/api/api_saveassistant`,
  CAMPAING_REPORT_GET_DATA: `${BASE_URL}/api/api_compaign_sleSmsReport`,
  CAMPAING_REPORT_GET_DATA_COMPOSE: `${BASE_URL}/api/api_compaign_template`,
  OUR_API_GENERATE_TOKEN: `${BASE_URL}/api/api_generate_token`,
  OUR_API_GENERIC: `${BASE_URL}/api/api_generic`,
  API_SUBSCRIBERS: `${BASE_URL}/api/api_users_by_group`,
  WEBINTEGRATION_HOME: `${BASE_URL}/api/api_update_webintegration_status`,
  FLOW_BUILDER_API: `${FLOW_BUILDER_URL}/api/api_auth_flowbuilder`,
  APPOINTMENT_API_GET_DATA: `${BASE_URL}/api/api_bot_retreive`,
  DATA_SEND_BIRTHDAY: `${BASE_URL}/api/api_bot_update_values`,
  DATA_SEND_BIRTHDAY_IMG_BODY: `${BASE_URL}/api/api_update_image_and_body`,
  DATA_SEND_EDIT_TAG: `${BASE_URL}/api/api_edit_tag`,
  DATA_SEND_TAG: `${BASE_URL}/api/api_create_tag`,
  DATA_SEND_CREATE_NEW_STEP_1: `${BASE_URL}/api/api_create_campaign`,
  DATA_SEND_CREATE_NEW_STEP_2: `${BASE_URL}/api/api_sms_step_two`,
  DATA_SEND_CREATE_NEW_STEP_3: `${BASE_URL}/api/api_get_users_by_tags_api`,
  DATA_SEND_CREATE_ALL_STEP: `${BASE_URL}/api/api_insert_sle_sms_report`,
  DATA_GET_EDIT_DATA_CAMPAIGN_REPORTS: `${BASE_URL}/api/api_single_compaign_sleSmsReport`,
  DATA_GET_EDIT_UPDATE_DATA_CAMPAIGN_REPORTS: `${BASE_URL}/api/api_update_campaign`,
  CAMPAIGN_REPORTS_DELETE: `${BASE_URL}/api/api_delete_campaign`,
  CAMPAIGN_REPORTS_COMPOSE_DETAIL: `${BASE_URL}/api/api_single_compaign_sleSms`,
  CAMPAIGN_Detail_REPORTS: `${BASE_URL}/api/api_delete_sle_sms`,
  XLSX_FILE_IMPORT: `${BASE_URL}/api/import`,
  TAGS_FETCH_DATA: `${BASE_URL}/api/api_users_by_group`,
  COMPOSE_MESSAGE_TABLE_DELETE: `${BASE_URL}/api/api_delete_campaign_template`,
  COMPOSE_MESSAGE_TABLE_EDIT_DATA: `${BASE_URL}/api/api_update_campaign_template`,
  COMPOSE_MESSAGE_TABLE_EDIT_GET_DATA: `${BASE_URL}/api/api_single_compaign_template`,
  COMPOSE_MESSAGE_TABLE_GET_DATA: `${BASE_URL}/api/`,
  YOUR_DATA_FETCH_DATA: `${BASE_URL}/api/api_get_generic_field`,
  API_BOT_UPDATE_ACTIVE: `${BASE_URL}/api/api_bot_update_active`,
  API_BOT_UPDATE_READY_SENT_50: `${BASE_URL}/api/api_updateReadySent_50`,
  API_BOT_UPDATE_DAILY_LIMIT: `${BASE_URL}/api/api_updateDailyLimit`,
  API_DELETE_USER_GRP: `${BASE_URL}/api/api_delete_user_group`,
  API_GENERATE_CAMPAIGN: `${BASE_URL}/api/api_generate_campaign`,
  API_STORE_IMPORT: `${BASE_URL}/api/api_store_imports`,
  API_MULTICHANEL_TOKEN: `${BASE_URL}/api/api_store_multichannel_token`,
  API_MOVE_USER_TAG: `${BASE_URL}/api/api_move_user_tags`,
};

export default API_ROUTES;

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import Switch from "@mui/material/Switch";
import { useLanguageState } from "../../../../context/LanguageContext";
import loadTranslations from "../../../../utils/loadTranslations";

const FourthCard = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const [followsMe, setFollowsMe] = useState(true);

  return (
    <MDBox>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={12} display="flex" alignItems="center">
          <Switch
            checked={followsMe}
            onChange={() => setFollowsMe(!followsMe)}
          />
          <MDTypography variant="h5">
            {translations.campaign_reports?.web_delivery} {followsMe ? "On" : "Off"}
          </MDTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <MDTypography variant="body1" style={{fontSize:"11px"}}>
            {translations.campaign_reports?.web_integrator_message}
          </MDTypography>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default FourthCard;

import React, { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import MDBox from "../../../components/MDBox";
import MDInput from "../../../components/MDInput";
import MDBadge from "../../../components/MDBadge";
import Breadcrumbs from "../../../examples/Breadcrumbs";
import NotificationItem from "../../../examples/Items/NotificationItem";
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "../../../examples/Navbars/DashboardNavbar/styles";
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "../../../context";
import axios from "axios";
import { useLogo } from "../../../context/LogoContext"; // Import useLogo hook
import API_ROUTES, { BASE_URL } from "../../../config/apiConfig";
import {
  useLanguageState,
  useLanguageDispatch,
} from "../../../context/LanguageContext";

import MenuItem from "@mui/material/MenuItem";

function DashboardNavbar({ absolute, light, isMini }) {
  const { logoURL } = useLogo(); // Access logoURL from context
  const [serverUrl, setServerUrl] = useState(null);

  const location = useLocation(); // Get the current location
  const currentRoute = location.pathname; // Extract the current route (e.g., "/newqr")

  useEffect(() => {
    if (currentRoute === "/multichanel") {
      const groupId = localStorage.getItem("group_id");

      if (groupId) {
        axios
          .post(API_ROUTES.HOME_API_STATS, {
            group_id: groupId,
          })
          .then((response) => {
            const { type, servers } = response.data;

            if (type === 1) {
              setServerUrl(currentRoute); // Keep the current route as it is
            } else if (type >= 2 && type <= 4) {
              const server = servers.find((s) => s.ID === type);
              if (server) {
                window.location.href = `https://${server.server_url}`;
              }
            } else {
              setServerUrl(currentRoute); // For other routes, keep the current route as it is
            }
          })
          .catch((error) => {
            console.error("API Error:", error);
            setServerUrl(currentRoute); // In case of error, use the original route
          });
      }
    } else {
      setServerUrl(currentRoute); // Handle other routes without making an API call
    }
  }, [currentRoute]); // Only re-run the effect if currentRoute changes

  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const history = useNavigate();
  const { language } = useLanguageState();
  const languageDispatch = useLanguageDispatch();

  const handleLanguageChange = (lang) => {
    localStorage.setItem("language", lang);
    languageDispatch({ type: "SET_LANGUAGE", payload: lang });
    setOpenMenu(false); // Close the main menu
    window.location.reload(); // Reload the page
  };

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleLogout = () => {
    localStorage.removeItem("group_id");
    localStorage.removeItem("name");
    history("/authentication/sign-in");
  };

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <MenuItem onClick={() => handleLanguageChange("english")}>
        English
      </MenuItem>
      <MenuItem onClick={() => handleLanguageChange("portuguese")}>
        Portuguese
      </MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const customBoxStyle = {
    display: "flex",
    alignItems: "center",
    border: "2px solid",
    borderColor: light || darkMode ? "white" : "black",
    borderRadius: "8px",
  };

  const textStyle = {
    marginLeft: "8px",
    color: light || darkMode ? "white" : "black",
  };

  const groupId = localStorage.getItem("group_id");
  const name = localStorage.getItem("name");

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.post(API_ROUTES.HOME_API_UPLOAD_FORM_GET, {
          group_id: groupId,
        });

        if (response.data.success) {
          const settings = response.data.data.user_group_setting[0];
          if (settings.logo) {
            const logoPath = `${BASE_URL}/${settings.logo}`;
            localStorage.setItem("logoURL", logoPath);
          }
        }
      } catch (error) {
        console.error("Failed to fetch images:", error);
      }
    };

    if (groupId) {
      fetchImages();
    }
  }, [groupId]);

  useEffect(() => {
    const fetchData = async () => {
      if (groupId && name) {
        try {
          const response = await fetch(API_ROUTES.SETTING_AI_GET_DATA_API_1ST, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ group_id: groupId, name }),
          });
          const result = await response.json();
          console.log("Data fetched: ", result);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      }
    };

    fetchData();
  }, [groupId, name]);

  const getBreadcrumbTitle = (route) => {
    const language = localStorage.getItem("language") || "portuguese";

    const titles = {
      english: {
        home: "Home",
        yourPublic: "Your Public",
        leads: "Leads and Vips",
        automatize: "Automatiza",
        flowbuilder: "Flowbuilder",
        your_data: "Your Data",
        ai_assistant: "Ai Assistant",
        settings: "Settings",
        upload_image: "Upload Image",
        messages_bots: "Message Bots",
        bot_reports: "Bot Reports",
        bot_birthday: "Bot Birthday",
        bot_welcome: "Bot Welcome",
        bot_appointment: "Bot Appointment",
        bot_firstpurchase: "Bot 1st Purchase",
        bot_expiring: "Bot Expiring",
        campaigns: "Campaigns",
        campaigns_reports: "Campaigns Reports",
        create_new: "Create New",
        integrations: "Integrations",
        our_api: "Our API",
        hotmart: "Hotmart",
        google_sheets: "Google Sheets",
        woo_commerce: "Woo Commerce",
        instagram_bot: "Instagram Bot",
        cupons: "Cupons",
        new_promotion: "New Promotion",
        cupons_list: "Cupons List",
        alert_messages: "Alert Messages",
        whatsapp_cloudapi: "Whatsapp Cloud API",
        metrics: "Metrics",
        others: "Others",
        staff: "Staff",
        lottery: "Lottery",
        cupons: "Coupons",
        linktudo: "Link-tudo",
        ecommerce: "Ecommerce",
        whatsapp: "Official Whatsapp API",
        compose_message: "Compose Message",
        generate_message: "Generate Campaign",
        campaign_report_detail: "Campaign Report Detail",
        tags: "Tags",
        newqr: "Read QRCode",
        multichannel: "Multichannel",
        edit_tag:"Entiquetas"
      },
      portuguese: {
        home: "Iniciar",
        yourPublic: "Seu Público",
        leads: "Leads e Vips",
        automatize: "Automatizar",
        flowbuilder: "Flowbuilder",
        your_data: "Seus Dados",
        ai_assistant: "Assistente IA",
        settings: "Configurações",
        upload_image: "Enviar Imagem",
        messages_bots: "Bots de Mensagens",
        bot_reports: "Relatórios de Bots",
        bot_birthday: "Bot de Aniversário",
        bot_welcome: "Bot de Boas-Vindas",
        bot_appointment: "Bot de Agendamento",
        bot_firstpurchase: "Bot de Primeira Compra",
        bot_expiring: "Bot de Vencimento",
        campaigns: "Campanhas",
        campaigns_reports: "Relatórios de Campanhas",
        create_new: "Criar Novo",
        integrations: "Integrações",
        our_api: "Nossa API",
        hotmart: "Hotmart",
        google_sheets: "Google Sheets",
        woo_commerce: "Woo Commerce",
        instagram_bot: "Bot do Instagram",
        cupons: "Cupons",
        new_promotion: "Nova Promoção",
        cupons_list: "Lista de Cupons",
        alert_messages: "Mensagens de Alerta",
        whatsapp_cloudapi: "API Cloud do WhatsApp",
        metrics: "Métricas",
        others: "Outros",
        staff: "Equipe",
        lottery: "Sorteio",
        cupons: "Cupons",
        linktudo: "Link-tudo",
        ecommerce: "Ecommerce",
        whatsapp: "Whatsapp Oficial",
        compose_message: "Compor Mensagem",
        generate_message: "Gerar Campanha",
        campaign_report_detail: "Detalhes da Campanha",
        tags: "Etiqueta",
        newqr: "Ler QRCode",
        multichannel: "Multiatendimento",
        edit_tag:"Entiquetas"
      },
    };

    const routeName = route[route.length - 1];
    const langTitles = titles[language] || titles.english;

    return langTitles[routeName] || routeName;
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={(theme) => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs
            icon="home"
            title={getBreadcrumbTitle(route)}
            route={route}
            light={light}
          />
          <IconButton
            sx={navbarDesktopMenu}
            onClick={handleMiniSidenav}
            size="small"
            disableRipple
          >
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={handleOpenMenu}
              >
                <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                  <MDBox sx={textStyle} style={{ marginRight: "8px" }}>
                    <div style={{ fontSize: "15px" }}>{name}</div>
                    <div style={{ marginTop: "0px", fontSize: "15px" }}>
                      ID {groupId}
                    </div>
                  </MDBox>
                  <MDBox
                    sx={customBoxStyle}
                    style={{
                      borderRadius: "5rem",
                      height: "4rem",
                      border: "none",
                    }}
                  >
                    {logoURL && (
                      <img
                        src={logoURL}
                        alt="Logo"
                        style={{
                          borderRadius: "50%",
                          height: "4rem",
                          width: "4rem",
                        }}
                      />
                    )}
                  </MDBox>
                </MDBox>
              </IconButton>
              {renderMenu()}
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;

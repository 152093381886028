import React, { createContext, useContext, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_ROUTES from '../config/apiConfig';

// Create a new context for authentication
const AuthContext = createContext();

// Define initial state for authentication
const initialState = {
  user: null,
  authError: null,
  loading: false,
};

// Define action types
const SET_USER = 'SET_USER';
const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
const SET_LOADING = 'SET_LOADING';

// Reducer function to handle authentication state
function authReducer(state, action) {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload, authError: null };
    case SET_AUTH_ERROR:
      return { ...state, authError: action.payload, user: null };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

// Authentication context provider
export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const navigate = useNavigate(); // Hook from react-router-dom for navigation

  const login = async (name, password) => {
    // Validate input fields
    if (!name || !password) {
      toast.error('Both name and password are required');
      return;
    }

    dispatch({ type: SET_LOADING, payload: true });

    try {
      const response = await axios.post(API_ROUTES.LOGIN, { login: name, password });

      if (response.data && response.data.data.user) {
        dispatch({ type: SET_USER, payload: response.data.data.user });
        localStorage.setItem("group_id", response.data.data.user.id); // Save user ID to localStorage
        localStorage.setItem("name", response.data.data.user.gp_groupname); // Save user ID to localStorage
        toast.success('Logged in Successfully.');
        window.location.href = "/home";
        // Redirect to /home page
      } else {
        throw new Error('Invalid credentials');
      }
    } catch (error) {
      let errorMessage = 'An unexpected error occurred';

      if (error.response && error.response.status === 401) {
        errorMessage = 'Invalid Credentials.';
      }

      dispatch({ type: SET_AUTH_ERROR, payload: errorMessage });
      toast.error(`${errorMessage}`); // Show toast notification
    } finally {
      dispatch({ type: SET_LOADING, payload: false });
    }
  };

  return (
    <AuthContext.Provider value={{ ...state, login }}>
      {children}
      {/* <ToastContainer /> */}
    </AuthContext.Provider>
  );
}

// Custom hook for using authentication context
export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

import React, { useEffect, useState } from "react";
import $ from "jquery";
import axios from "axios";

// @mui/material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 PRO React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import ReportsBarChart from "../../examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "../../examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "../../examples/Cards/StatisticsCards/ComplexStatisticsCard";
import BookingCard from "../../examples/Cards/BookingCard";

// Anaytics dashboard components
import SalesByCountry from "../../layouts/dashboards/analytics/components/SalesByCountry";
import PlansAndInvoices from "../../layouts/dashboards/analytics/components/plansAndInvoices";

// Images
import thumbnail from "../../assets/images/thumbnail.jpg";
import WhatsappAndQr from "./whatsappApi";
import TermsOfUse from "./components/TermsOfUse";
import { Card } from "@mui/material";
import UploadForm from "./components/UploadForm";
import QRCodeHandle from "./qrCodeHandler";
import GooglePixel from "./components/GooglePixel";
import MetaPixel from "./components/MetaPixel";
import API_ROUTES from "../../config/apiConfig";
import { toast } from "react-toastify";
import { useLanguageState } from "../../context/LanguageContext";
import loadTranslations from "../../utils/loadTranslations";
import MDButton from "../../components/MDButton";
import { useNavigate } from "react-router-dom";
import MDInput from "../../components/MDInput";
import { dark } from "@mui/material/styles/createPalette";

const Home = () => {
  // const [followsMe, setFollowsMe] = useState(true);
  const [followsMe1, setFollowsMe1] = useState(false);
  const [automation, setAutomation] = useState(false);
  const [statistics, setStatistics] = useState({
    totalUsers: 0,
    birthdaysThisMonth: 0,
    birthdaysToday: 0,
  });

  useEffect(() => {
    const groupId = localStorage.getItem("group_id");
    if (groupId) {
      axios
        .post(API_ROUTES.HOME_API_STATS, {
          group_id: groupId,
        })
        .then((response) => {
          const data = response.data;
          setStatistics({
            totalUsers: data.cnt_total_users,
            birthdaysThisMonth: data.db,
            birthdaysToday: data.day_birt,
          });
          setAutomation(data.automation === 1);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log(loadedTranslations.page_home.total_users);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const handleAutomationChange = () => {
    const groupId = localStorage.getItem("group_id");
    const newAutomationValue = !automation ? 1 : 0;

    axios
      .post(API_ROUTES.WEBINTEGRATION_HOME, {
        group_id: groupId,
        is_active: newAutomationValue,
      })
      .then((response) => {
        setAutomation(!automation);
        toast.success("Automation status updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating automation status:", error);
        toast.error("Failed to update automation status.");
      });
  };

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get("https://crmstation.net/laravel/public/api/api_get_videos?page=home")
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  // Define initial state
  const [apiData, setApiData] = useState(null);

  const [selectedServerUrl, setSelectedServerUrl] = useState("");

  // Fetch data from API
  useEffect(() => {
    const groupId = localStorage.getItem("group_id");
    if (groupId) {
      axios
        .post(API_ROUTES.HOME_API_STATS, {
          group_id: groupId,
        })
        .then((response) => {
          setApiData(response.data);

          // Extract the type from the response
          const { type, servers } = response.data;

          // Find the server that matches the type
          const matchedServer = servers.find((server) => server.ID === type);

          // If a matching server is found, store its server_url in the state variable
          if (matchedServer) {
            setSelectedServerUrl(matchedServer.server_url);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const navigate = useNavigate();

  const handleQrButtonClick = () => {
    if (apiData) {
      const { type, servers } = apiData;

      if (type === 1) {
        navigate("/newqr");
      } else if ([2, 3, 4].includes(type)) {
        const server = servers.find((srv) => srv.ID === type);
        if (server) {
          navigate("/ticket-qr");
        }
      }
    }
  };

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");

  const group_id = localStorage.getItem("group_id");
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = () => {
    setIsSaving(true);

    // Replace this with your actual API call
    axios
      .post(API_ROUTES.API_MULTICHANEL_TOKEN, {
        group_id,
        token: inputValue2,
      })
      .then((response) => {
        // Show success toast notification
        toast.success(translations.toast_messages?.save_success);
      })
      .catch((error) => {
        // Show error toast notification
        toast.error(translations.toast_messages?.save_error);
        console.error("Error saving token:", error);
      })
      .finally(() => {
        setIsSaving(false); // Re-enable the button after the request is complete
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={6}>
          <Grid container spacing={3}>
            {videoData.map((video, index) => (
              <Grid item xs={6} md={6} lg={2} key={index}>
                <MDBox mt={3}>
                  <BookingCard
                    image={thumbnail}
                    title={video.title}
                    description={video.description} // Assuming the description is part of the API response
                    price={video.price} // Assuming the price is part of the API response
                    location={video.location} // Assuming the location is part of the API response
                    link={video.link}
                  />
                </MDBox>
              </Grid>
            ))}
          </Grid>

          <MDBox mt={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="green"
                    icon="leaderboard"
                    title={translations.page_home?.total_users}
                    count={statistics.totalUsers}
                    percentage={{
                      color: "success",
                      amount: "+55%",
                      label: translations.page_home?.than_lastmonth,
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="celebration"
                    color="red"
                    title={translations.page_home?.birthday_thismonth}
                    count={statistics.birthdaysThisMonth}
                    percentage={{
                      color: "success",
                      amount: "+3%",
                      label: translations.page_home?.than_lastmonth,
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="pink"
                    icon="cake"
                    title={translations.page_home?.birthday_today}
                    count={statistics.birthdaysToday}
                    percentage={{
                      color: "success",
                      amount: "+1%",
                      label: translations.page_home?.than_yesterday,
                    }}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox mt={4}>
  <Grid container spacing={3}>
    <Grid item xs={12} md={6} lg={4}>
      <MDBox mb={1.5} sx={{ height: "100%" }}>
        <Card sx={{ height: "100%" }}>
          <MDBox>
            <MDTypography
              variant="h6"
              sx={{ mt: 2, ml: 2 }}
              id="message_container1"
            >
              {translations.page_home?.automation_status}
            </MDTypography>
          </MDBox>
          <MDBox p={3} sx={{ height: "100%" }}>
            {/* Content Row */}
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "50px", marginBottom: "1.5rem" }}
            >
              <MDButton
                type="button"
                variant="gradient"
                color="info"
                sx={{ width: "13rem" }}
                onClick={handleQrButtonClick}
              >
                {translations.page_home?.read_qr}
              </MDButton>
            </MDBox>

            {/* Switch Row 1 */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "50px", marginBottom: "1rem" }}
            >
              <MDTypography variant="h6" color="textSecondary">
                {automation
                  ? `${translations.page_home?.automation_is} ON`
                  : `${translations.page_home?.automation_is} OFF`}
              </MDTypography>
              <Switch
                checked={automation}
                onChange={handleAutomationChange}
              />
            </MDBox>

            {/* Switch Row 2 */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "50px" }}
            >
              <MDTypography variant="h6" color="textSecondary">
                {followsMe1
                  ? `${translations.page_home?.aiassistant_is} ON`
                  : `${translations.page_home?.aiassistant_is} OFF`}
              </MDTypography>
              <Switch
                checked={followsMe1}
                onChange={() => setFollowsMe1(!followsMe1)}
              />
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Grid>

    <Grid item xs={12} md={6} lg={8}>
      <MDBox mb={1.5} sx={{ height: "100%" }}>
        <Card sx={{ height: "100%" }}>
          <MDBox>
            <MDTypography
              variant="h6"
              sx={{ mt: 2, ml: 2 }}
              id="message_container1"
            >
              {translations.page_home?.automation_multichanel_details}
            </MDTypography>
          </MDBox>
          <MDBox p={3} sx={{ height: "100%" }}>
            {/* First Row */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "50px", marginBottom: "1rem" }}
            >
              <MDBox width="33%">
                <MDTypography
                  component="p"
                  variant="button"
                  color="text"
                  display="flex"
                >
                  {translations.page_home?.multichanel_server}
                </MDTypography>
              </MDBox>
              <MDBox width="66%">
                <MDTypography
                  variant="body1"
                  style={{ color: "#8220d0", fontSize: "11px" }}
                >
                  {selectedServerUrl}
                </MDTypography>
              </MDBox>
            </MDBox>

            {/* Second Row */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "50px", marginBottom: "1rem" }}
            >
              <MDBox width="33%">
                <MDTypography
                  component="p"
                  variant="button"
                  color="text"
                  display="flex"
                >
                  {translations.page_home?.multichanel_token}
                </MDTypography>
              </MDBox>
              <MDBox
                width="66%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <MDInput
                  type="text"
                  fullWidth
                  placeholder={
                    translations.page_home?.input_placeholder_2
                  }
                  value={inputValue2}
                  onChange={(e) => setInputValue2(e.target.value)}
                />
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleSave}
                  disabled={isSaving}
                >
                  {isSaving
                    ? translations.buttons_and_common_words?.saving ||
                      "Saving..."
                    : translations.buttons_and_common_words?.save}
                </MDButton>
              </MDBox>
            </MDBox>

            {/* Third Row */}
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ height: "50px" }}
            >
              <MDBox width="33%">
                <MDTypography
                  component="p"
                  variant="button"
                  color="text"
                  display="flex"
                >
                  {translations.page_home?.automation_restart_timer}
                </MDTypography>
              </MDBox>
              <MDBox
                width="66%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <MDInput
                  type="text"
                  fullWidth
                  placeholder={
                    translations.page_home?.input_placeholder_2
                  }
                  value={inputValue1}
                  onChange={(e) => setInputValue1(e.target.value)}
                />
                <MDButton
                  variant="gradient"
                  color="dark"
                  onClick={handleSave}
                >
                  {translations.buttons_and_common_words?.save}
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </Grid>
  </Grid>
</MDBox>


          <MDBox mt={1.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <MDBox mt={3}>
                  <PlansAndInvoices />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <MDBox mt={3}>
                  <TermsOfUse />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={6}>
            <UploadForm />
          </MDBox>

          <MDBox mt={1.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mt={3}>
                  <GooglePixel />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mt={3}>
                  <MetaPixel />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "./DataTable";
import API_ROUTES from "../../config/apiConfig";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";
import PolicyIcon from "@mui/icons-material/Policy";
import MDButton from "../../components/MDButton";
import { NavLink, useNavigate } from "react-router-dom";
import EditIcon from "../../assets/images/edit-icon.png";
import BookingCard from "../../examples/Cards/BookingCard";
import Select from "react-select";
import { toast } from "react-toastify";
import { ImportExport as ImportIcon } from "@mui/icons-material";

// Images
import thumbnail from "../../assets/images/thumbnail.jpg";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import { Padding } from "@mui/icons-material";
import ImportModal from "./ImportModal";
import UserExportButton from "./UserExportButton";

const UsersTable = () => {
  const [openModal, setOpenModal] = useState(false);
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [cellphone, setCellphone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const group_id = localStorage.getItem("group_id");
  const [total, setTotal] = useState("");
 

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  // Add state for errors
  const [errors, setErrors] = useState({});

  // Add validation and update the handleAddSubscriber function
  const handleAddSubscriber = async () => {
    const tagIDs = selectedTags.map((tag) => tag.value);
    const encodedTagIDs = encodeURIComponent(tagIDs.join(","));
    console.log("Tag IDs:", tagIDs);
    console.log("Encoded Tag IDs:", encodedTagIDs);

    // Validate required fields
    const newErrors = {};
    if (!name) newErrors.name = true;
    if (!company) newErrors.company = true;
    if (!cellphone) newErrors.cellphone = true;
    if (!email) newErrors.email = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Please fill in all required fields.");
      return; // Exit function if validation fails
    }

    // Send data to backend API
    const subscriberData = {
      name,
      company,
      mobile: cellphone,
      email,
      groupid: group_id,
    };

    try {
      const response = await fetch(
        "https://321dbase.com/api/user_save_profile.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(subscriberData),
        }
      );

      const result = await response.json();
      console.log(response);

      if (response.ok) {
        toast.success("Subscriber added successfully!");
      } else {
        toast.error(`Error: ${result.message}`);
      }
    } catch (error) {
      toast.error("Failed to add subscriber");
    }

    handleClose();
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setUserIdToDelete(null);
  };

  const [reloadData, setReloadData] = useState(false); // Add this state

  const handleConfirmDelete = async () => {
    try {
      const response = await axios.post(`${API_ROUTES.API_DELETE_USER_GRP}`, {
        group_id,
        user_id: userIdToDelete,
      });

      if (response) {
        toast.success("User Deleted Successfully");
        setReloadData(!reloadData); // Toggle reloadData to trigger re-fetch
      }
    } catch (error) {
      toast.error("Error deleting user.");
      console.error("Error deleting user:", error);
    } finally {
      handleCloseDeleteModal();
    }
  };

  const columns = translations.page_subscribers
    ? [
        { Header: "ID", accessor: "ID", width: "10%" },
        {
          Header: translations.page_subscribers.name,
          accessor: "name",
          width: "15%",
        },
        {
          Header: translations.page_subscribers.mobile,
          accessor: "mobile",
          width: "10%",
        },
        {
          Header: translations.page_subscribers.dob,
          accessor: "dob",
          width: "10%",
        },
        {
          Header: translations.page_subscribers.company,
          accessor: "company",
          width: "15%",
        },
        {
          Header: translations.page_subscribers.lead,
          accessor: "lead",
          width: "10%",
        },
        {
          Header: translations.page_subscribers.created_at,
          accessor: "createdAt",
          width: "10%",
        },
        {
          Header: translations.page_subscribers.updated_at,
          accessor: "updatedAt",
          width: "10%",
        },
        {
          Header: translations.page_subscribers.actions,
          accessor: "actions",
          width: "10%",
        },
      ]
    : [];

  const formatData = (data) =>
    data.map((user) => ({
      ID: user.user_id,
      name: user.fname,
      mobile: user.mobile,
      dob: `${user.dob_day}/${user.dob_month}/${user.dob_year}`,
      company: user.company || "N/A",
      lead: user.lead || "N/A",
      createdAt: new Date(user.created_at).toLocaleDateString(),
      updatedAt: new Date(user.updated_at).toLocaleDateString(),
      actions: (
        <div style={{ display: "flex", gap: "5px" }}>
          <NavLink
            to={`/edit-subscriber/${user.user_id}`}
            style={{ textDecoration: "none" }}
          >
            <IconButton color="primary" aria-label="edit">
              <img src={EditIcon} alt="" style={{ width: "20px" }} />
            </IconButton>
          </NavLink>
          <IconButton
            aria-label="delete"
            style={{ color: "#f72585" }}
            onClick={() => handleDelete(user.user_id)}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    }));

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=your_public"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  const [selectedTag1, setSelectedTag1] = useState(0);
  const [dobFilter, setDobFilter] = useState("");

  const handleTagChange1 = (event) => {
    setSelectedTag1(event.target.value);
  };

  const handleDobChange = (event) => {
    setDobFilter(event.target.value);
  };

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/tags");
  };

  // Function to fetch tags, now a separate function for easier reuse
  const fetchTags = async () => {
    const group_id = localStorage.getItem("group_id");
    try {
      const response = await fetch(
        `https://crmstation.net/laravel/public/api/api_users_by_group?group_id=${group_id}`
      );
      const data = await response.json();
      if (data.status === "success") {
        setTags(data.tags.map((tag) => ({ value: tag.id, label: tag.tag })));
        setTotal(data.total);
      }
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  // Fetch tags when the component mounts or reloadData changes
  useEffect(() => {
    fetchTags();
  }, [reloadData]);

  // Function to toggle reloadData, triggering re-fetch
  const refreshTags = () => {
    setReloadData(!reloadData);
  };


  const [importModalOpen, setImportModalOpen] = useState(false);

  const handleImportClick = () => {
    setImportModalOpen(true);
  };

  const handleImportClose = () => {
    setImportModalOpen(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <MDBox mt={2}>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={0}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                  <h3>Video not found</h3>
                </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>

        <Box mb={4} mt={4}>
          <Grid container spacing={3}>
            {/* Dropdown for Search by Tags */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">
                {translations.page_subscribers?.search_bytag}
              </Typography>
              <TextField
                select
                fullWidth
                value={selectedTag1}
                onChange={handleTagChange1}
                variant="outlined"
                label={translations.page_subscribers?.select_tag}
                style={{
                  marginTop: 8,
                  borderRadius: 4,
                  height: 56, // Consistent height
                  textAlign: "left",
                }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: {
                    height: "100%",
                    padding: "0 14px", // Adjust padding to ensure text is aligned
                  },
                }}
              >
                <MenuItem value="0">
                  All {translations.edit_subscribers?.tags}
                </MenuItem>
                {tags.map((tag) => (
                  <MenuItem key={tag.value} value={tag.value}>
                    {tag.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* Dropdown for Filter by DOB */}
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6">
                {translations.page_subscribers?.filter_bybirthday}
              </Typography>
              <TextField
                type="date"
                value={dobFilter}
                onChange={handleDobChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                style={{
                  marginTop: 8,
                  borderRadius: 4,
                  height: 56, // Consistent height
                  textAlign: "left",
                }}
                InputProps={{
                  style: {
                    height: "100%",
                    padding: "0 14px", // Adjust padding to ensure text is aligned
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h6"></Typography>
              <MDButton
                variant="gradient"
                style={{
                  backgroundColor: "#4cc9f0",
                  color: "white",
                  marginTop: "2.5rem",
                }}
              >
                {translations.page_subscribers?.meta_custom_public}
              </MDButton>
            </Grid>
          </Grid>
        </Box>

        <MDBox mt={4}>
          <Card>
            <MDBox
              p={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h5" fontWeight="medium">
                <span style={{ fontSize: "1.2rem" }}>
                  {" "}
                  {/* Apply smaller font size and greyish color */}
                  {translations.page_subscribers?.subscribers_table}
                </span>
                <span style={{ fontSize: "0.875rem", color: "grey" }}>
                  {" "}
                  {/* Apply smaller font size and greyish color( Total
                  Subscribers: {total} ) */}
                </span>
              </MDTypography>
              {/* ({total}) */}
              <span>
                <UserExportButton />
                <MDButton
                  variant="gradient"
                  style={{
                    backgroundColor: "#3f37c9",
                    color: "white",
                    marginLeft: "8px",
                  }}
                  startIcon={<ImportIcon />}
                  onClick={handleImportClick}
                >
                  {translations.buttons_and_common_words?.import}
                </MDButton>
                <MDButton
                  variant="gradient"
                  style={{
                    backgroundColor: "#f72585",
                    color: "white",
                    marginLeft: "8px",
                  }}
                  onClick={handleButtonClick}
                >
                  {translations.edit_subscribers?.tags}
                </MDButton>
                <MDButton
                  variant="gradient"
                  style={{
                    backgroundColor: "#480ca8",
                    color: "white",
                    marginLeft: "8px",
                  }}
                  startIcon={<AddIcon />}
                  onClick={handleOpen}
                >
                  {translations.page_subscribers?.add_new_subscriber}
                </MDButton>
              </span>
              <ImportModal open={importModalOpen} onClose={handleImportClose} group_id={group_id} refreshTags={refreshTags} />
            </MDBox>
            {columns.length > 0 && (
              <DataTable
                columns={columns}
                apiEndpoint={API_ROUTES.API_SUBSCRIBERS}
                formatData={formatData}
                tag={selectedTag1}
                total={total}
                canSearch
                reloadData={reloadData}
              />
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />

      {/* Modal */}
      <Modal open={openModal} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            {translations.page_subscribers?.add_new_subscriber}
          </Typography>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`Enter ${translations.edit_subscribers?.fullname}`}
                placeholder={`Enter ${translations.edit_subscribers?.fullname}`}
                variant="outlined"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setErrors((prev) => ({ ...prev, name: false }));
                }}
                error={!!errors.name} // Show red border if there's an error
                helperText={errors.name ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`Enter ${translations.page_subscribers?.company}`}
                placeholder={`Enter ${translations.page_subscribers?.company}`}
                variant="outlined"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  setErrors((prev) => ({ ...prev, company: false }));
                }}
                error={!!errors.company} // Show red border if there's an error
                helperText={errors.company ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`Enter ${translations.page_subscribers?.mobile}`}
                placeholder={`Enter ${translations.page_subscribers?.mobile}`}
                variant="outlined"
                value={cellphone}
                onChange={(e) => {
                  setCellphone(e.target.value);
                  setErrors((prev) => ({ ...prev, cellphone: false }));
                }}
                error={!!errors.cellphone} // Show red border if there's an error
                helperText={errors.cellphone ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`Enter ${translations.edit_subscribers?.email}`}
                placeholder={`Enter ${translations.edit_subscribers?.email}`}
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setErrors((prev) => ({ ...prev, email: false }));
                }}
                error={!!errors.email} // Show red border if there's an error
                helperText={errors.email ? "This field is required" : ""}
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                isMulti
                options={tags}
                value={selectedTags}
                onChange={handleTagChange}
                placeholder="Select Tags"
              />
            </Grid>
          </Grid>
          <Box mt={3} display="flex" justifyContent="flex-end" gap={2}>
            <MDButton
              variant="gradient"
              color="black1"
              onClick={handleClose}
              style={{ color: "black" }}
            >
              Cancel
            </MDButton>
            <MDButton
              variant="gradient"
              color="dark"
              onClick={handleAddSubscriber}
            >
              {translations.buttons_and_common_words?.save}
            </MDButton>
          </Box>
        </Box>
      </Modal>

      {/* Modal deletion */}
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="delete-confirmation-modal"
        aria-describedby="delete-confirmation-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="delete-confirmation-modal-title"
            variant="h6"
            component="h2"
          >
            {translations.buttons_and_common_words?.are_you_sure}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <MDButton
              onClick={handleCloseDeleteModal}
              color="black1"
              variant="gradient"
              style={{ color: "black" }}
            >
              {translations.buttons_and_common_words?.cancel}
            </MDButton>
            <MDButton
              onClick={handleConfirmDelete}
              color="error"
              variant="gradient"
              style={{ marginLeft: "5px" }}
            >
              {translations.buttons_and_common_words?.ok}
            </MDButton>
          </Box>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default UsersTable;

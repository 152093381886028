import React from "react";
import { Card, CardContent, Typography, Avatar, Grid } from "@mui/material";

const SimpleCard = ({ title, email, createdAt, avatarUrl }) => {
  return (
    <Card variant="outlined">
      <CardContent
        style={{
          padding: "12px",
          display: "flex",
          alignItems: "center",
          position: "relative", // To allow absolute positioning within the CardContent
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Avatar
              alt={title}
              src={avatarUrl}
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                border: "2px solid #4cc9f0", // Optional: Add a border color
              }}
            />
          </Grid>
          <Grid item>
            <Typography variant="h6" component="div" style={{ fontSize: "12px" }}>
              {title}
            </Typography>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ fontSize: "12px" }}
            >
              {email}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          variant="h6"
          color="textSecondary"
          style={{
            fontSize: "11px",
            color: "#8080809e",
            position: "absolute",
            top: "8px", // Adjust based on your padding/margins
            right: "12px", // Adjust based on your padding/margins
          }}
        >
          {createdAt}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default SimpleCard;

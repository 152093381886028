import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import IllustrationLayout from "../../../../layouts/authentication/components/IllustrationLayout";
import bgImage from "../../../../assets/images/illustrations/signin.jpg";
import { useAuth } from "../../../../context/authContext"; // Adjust the import path according to your project structure
import loadTranslations from "../../../../utils/loadTranslations";
import { useLanguageState } from "../../../../context/LanguageContext";

function Basic() {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({}); // Initially null

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const { login, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if group_id and name exist in localStorage
    if (
      localStorage.getItem("group_id") !== null &&
      localStorage.getItem("name") !== null
    ) {
      window.location.href = "/home";
    }
  }, [navigate]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async (event) => {
    event.preventDefault();
    await login(name, password);
  };

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      console.log("Loaded Translations: ", loadedTranslations); // Add this line
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <IllustrationLayout
      // title="Sign In"
      description={translations.signin?.name_password_signin}
      illustration={bgImage}
      style={{ backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
    >
      <MDBox component="form" role="form" onSubmit={handleLogin}>
        <MDBox mb={2}>
          <MDInput
            type="text"
            label={translations.signin?.user}
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label={translations.signin?.password}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;{translations.signin?.remember_me}
          </MDTypography>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            fullWidth
            type="submit"
            disabled={loading}
          >
            {loading ? "Signing in..." : translations.signin?.signin}
          </MDButton>
        </MDBox>
      </MDBox>
    </IllustrationLayout>
  );
}

export default Basic;

import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import {
  Card,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  DialogContentText,
} from "@mui/material";

import Box from "@mui/material/Box";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import API_ROUTES from "../../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import MDButton from "../../../components/MDButton";
import { useFormData } from "../../../context/FormDataContext";
import { useGlobalData } from "../../../context/GlobalDataContext"; // Import the global context
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import BookingCard from "../../../examples/Cards/BookingCard";
import thumbnail from "../../../assets/images/thumbnail.jpg";
import loadTranslations from "../../../utils/loadTranslations";
import { useLanguageState } from "../../../context/LanguageContext";
import DataTable from "../../../examples/Tables/DataTable";
import IconButton from "@mui/material/IconButton";
// import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "../../../assets/images/edit-icon.png";

function ComposeMessage() {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [editedMessageName, setEditedMessageName] = useState("");
  const [editedMessageBody, setEditedMessageBody] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  const { formData, setFormData } = useFormData();
  const { state, dispatch } = useGlobalData();
  const [campaignName, setCampaignName] = useState(formData.campaignName || "");
  const [smsBody, setSmsBody] = useState(formData.smsBody || "");
  const [selectedImage, setSelectedImage] = useState(
    formData.selectedImage || null
  );
  const [imageFile, setImageFile] = useState(null);
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saving1, setSaving1] = useState(false); // Initialize saving state

  useEffect(() => {
    if (formData.campaignName) {
      setCampaignName(formData.campaignName);
    }
    if (formData.smsBody) {
      setSmsBody(formData.smsBody);
    }
    if (formData.selectedImage) {
      setSelectedImage(formData.selectedImage);
    }
  }, [formData]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(URL.createObjectURL(event.target.files[0]));
      setImageFile(event.target.files[0]);
    }
  };

  const resetForm = () => {
    setCampaignName("");
    setSmsBody("");
    setSelectedImage(null);
    setImageFile(null);
    setError("");
    setFormData({});
    setEditedMessageName("");
    setEditedMessageBody("");
  };

  const navigate = useNavigate();
  const group_id = localStorage.getItem("group_id");

  const handleSubmit = async () => {
    if (!campaignName) {
      toast.error("Campaign name is required");
      setError("Campaign name is required");
      return;
    }

    setIsSubmitting(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("campaign_name", campaignName);
      formDataToSend.append("sms_body", smsBody);
      formDataToSend.append("group_id", group_id);
      if (imageFile) {
        formDataToSend.append("image", imageFile);
      }

      const response = await axios.post(
        API_ROUTES.DATA_SEND_CREATE_NEW_STEP_1,
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        setFormData({ campaignName, smsBody, selectedImage });

        dispatch({ type: "SET_STEP_ONE_DATA", payload: response.data.data });
        toast.success(translations.compose_message?.message_saved);

        resetForm();
      } else {
        toast.error("Error submitting form data");
      }
    } catch (error) {
      toast.error("Error submitting form data");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Fetch table data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          API_ROUTES.CAMPAING_REPORT_GET_DATA_COMPOSE,
          { group_id }
        );
        const fetchedData = response.data.data.map((item) => ({
          id: item.id,
          messageName: item.campaign_name, // Updated key to match your data
          createDate: item.created_at, // This key remains the same
          actions: (
            <div style={{ display: "flex", gap: "10px" }}>
              <IconButton
                aria-label="edit"
                onClick={() => handleEditClick(item.id)}
                style={{ color: "#b5179e" }}
              >
                <img src={EditIcon} alt="" style={{ width: "20px" }} />
              </IconButton>
              <IconButton
                color="secondary"
                aria-label="delete"
                onClick={() => handleDeleteClick(item.id)}
                style={{ color: "#f72585" }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ),
        }));
        setTableData({
          columns: [
            { Header: "ID", accessor: "id", width: "10%" },
            { Header: translations.compose_message?.name_campaign || "Nome da Mensagem", accessor: "messageName", width: "40%" },
            { Header: translations.page_subscribers?.created_at || "Criado em", accessor: "createDate", width: "25%", Cell: ({ value }) => new Date(value).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' }) },

            { Header: translations.page_subscribers?.actions || "Ações", accessor: "actions", width: "25%" },
          ],
          rows: fetchedData,
        });
      } catch (error) {
        console.error("There was an error fetching the table data!", error);
      }
    };

    fetchData();
  }, []);

  const [editedImageName, setEditedImageName] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [saving, setSaving] = useState(false);

  const handleEditClick = async (id) => {
    console.log(id);

    try {
      const response = await axios.post(
        API_ROUTES.COMPOSE_MESSAGE_TABLE_EDIT_GET_DATA,
        { group_id, campaign_id: id },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const message = response.data.data[0]; // Assuming you want to use the first object in the data array

      // Set the relevant fields to state
      setSelectedMessage(message);
      setEditedMessageName(message.campaign_name); // Campaign Name
      setEditedMessageBody(message.sms_body); // SMS Body
      setEditedImageName(message.image_name); // Image Name
      setEditModalOpen(true); // Open the modal
    } catch (error) {
      console.error("Error fetching message data!", error);
    }
  };

  const handleImageChange1 = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImageFile(file);
      setEditedImageName(file.name); // Optionally update the image name in the state
    }
  };

  const handleEditSave = async () => {
    setSaving1(true); // Start saving, disable the button, and show "Saving..."

    const formData = new FormData();
    formData.append("campaign_name", editedMessageName);
    formData.append("sms_body", editedMessageBody);
    formData.append("group_id", group_id);
    formData.append("campaign_id", selectedMessage.id);
    if (selectedImageFile) {
      formData.append("image", selectedImageFile);
    }

    try {
      const response = await axios.post(
        API_ROUTES.COMPOSE_MESSAGE_TABLE_EDIT_DATA,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Updated Successfully!");

      // Handle successful save
      // Close modal, show a success message, etc.
      setEditModalOpen(false);
    } catch (error) {
      console.error("Error saving message data!", error);
    } finally {
      setSaving1(false); // Reset saving state, enable the button, and show "Save"
    }
  };

  const [videoData, setVideoData] = useState([]);

  useEffect(() => {
    axios
      .get(
        "https://crmstation.net/laravel/public/api/api_get_videos?page=message_reports"
      )
      .then((response) => {
        setVideoData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the video data!", error);
      });
  }, []);

  const handleDeleteClick = (id) => {
    setSelectedMessage(id);
    setDeleteModalOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteModalOpen(false);
    setSelectedMessage(null);
  };

  const confirmDelete = () => {
    setLoading(true);
    console.log(selectedMessage);
    console.log(group_id);

    axios
      .post(`${API_ROUTES.COMPOSE_MESSAGE_TABLE_DELETE}`, {
        campaign_id: selectedMessage,
        group_id: group_id,
      })
      .then(() => {
        toast.success("Message deleted successfully!");
        setDeleteModalOpen(false);
        setTableData((prevData) => ({
          ...prevData,
          rows: prevData.rows.filter((row) => row.id !== selectedMessage),
        }));
      })
      .catch((error) => {
        console.error("Error deleting message!", error);
        toast.error("Failed to delete message");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox>
          <Grid container spacing={3}>
            {videoData.length > 0 ? (
              videoData.map((video, index) => (
                <Grid item xs={6} md={6} lg={2} key={index}>
                  <MDBox mt={3}>
                    <BookingCard
                      image={thumbnail}
                      title={video.title}
                      description={video.description} // Assuming the description is part of the API response
                      price={video.price} // Assuming the price is part of the API response
                      location={video.location} // Assuming the location is part of the API response
                      link={video.link}
                    />
                  </MDBox>
                </Grid>
              ))
            ) : (
              <Grid item xs={12}>
                {/* <MDBox textAlign="center" mt={3}>
                         <h3>Video not found</h3>
                       </MDBox> */}
              </Grid>
            )}
          </Grid>
        </MDBox>
        <Grid container justifyContent="center" mt={4}>
          <Grid item xs={12} md={8} lg={12}>
            <Card>
              <MDTypography variant="h6" sx={{ mt: 2, ml: 3 }}>
                {translations.compose_message?.compose_message}
              </MDTypography>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label={translations.compose_message?.name_campaign}
                      name="campaign_name"
                      placeholder={translations.compose_message?.name_campaign}
                      fullWidth
                      value={campaignName}
                      onChange={(e) => {
                        setCampaignName(e.target.value);
                        setError("");
                      }}
                      error={!!error}
                      helperText={error}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="text"
                      label={translations.compose_message?.enter_text}
                      name="sms_body"
                      placeholder={translations.compose_message?.enter_text}
                      fullWidth
                      multiline
                      rows={8}
                      value={smsBody}
                      onChange={(e) => setSmsBody(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={6}>
                    <Box
                      sx={{
                        p: 2,
                        border: "1px dashed grey",
                        borderRadius: "4px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#f0f0f0",
                        cursor: "pointer",
                        height: "100%",
                      }}
                      onClick={() =>
                        document.getElementById("imageUpload").click()
                      }
                    >
                      {selectedImage ? (
                        <img
                          src={selectedImage}
                          alt="Uploaded"
                          style={{ maxHeight: "100%", width: "50%" }}
                        />
                      ) : (
                        <MDTypography variant="body1" color="textSecondary">
                          {translations.page_home?.upload_logo_images}
                        </MDTypography>
                      )}
                    </Box>
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="h6">
                      Use %name% or %nome% to customize the Subscriber's name
                    </MDTypography>
                  </Grid>
                </Grid>
                <MDBox
                  mt={3}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MDButton
                    type="button"
                    variant="gradient"
                    color="dark"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    {isSubmitting
                      ? translations.buttons_and_common_words?.saving
                      : translations.buttons_and_common_words?.save}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        {/* New DataTable Card */}
        <MDBox mt={6}>
          <Card>
            <DataTable table={tableData} canSearch />
          </Card>
        </MDBox>

        {/* Edit Modal */}
        <Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <DialogTitle>Edit Message</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Message Name"
              type="text"
              fullWidth
              value={editedMessageName}
              onChange={(e) => setEditedMessageName(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Message Body"
              type="text"
              fullWidth
              multiline
              rows={4}
              value={editedMessageBody}
              onChange={(e) => setEditedMessageBody(e.target.value)}
            />

            {/* Image Preview */}
            {editedImageName && (
              <img
                src={`${editedImageName}`}
                alt="Campaign"
                style={{ width: "100%", marginTop: "10px" }}
              />
            )}

            {/* Image Upload */}
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageChange1(e)}
              style={{ marginTop: "20px" }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={() => setEditModalOpen(false)}
              variant="black1"
              style={{ color: "black" }}
            >
              Cancel
            </MDButton>
            <MDButton
              onClick={handleEditSave}
              variant="gradient"
              disabled={saving} // Disable the MDButton while saving
              color="dark"
            >
              {saving
                ? translations.buttons_and_common_words?.saving
                : translations.buttons_and_common_words?.save}{" "}
              {/* Show "Saving..." or "Save" based on the state */}
            </MDButton>
          </DialogActions>
        </Dialog>
      </MDBox>
      <Footer />

      {/* Edit Modal */}
<Dialog open={editModalOpen} onClose={() => setEditModalOpen(false)}>
  <DialogTitle>Edit Message</DialogTitle>
  <DialogContent>
    <TextField
      autoFocus
      margin="dense"
      label="Message Name"
      type="text"
      fullWidth
      value={editedMessageName}
      onChange={(e) => setEditedMessageName(e.target.value)}
    />
    <TextField
      margin="dense"
      label="Message Body"
      type="text"
      fullWidth
      multiline
      rows={4}
      value={editedMessageBody}
      onChange={(e) => setEditedMessageBody(e.target.value)}
    />

    {/* Image Preview */}
    {editedImageName && !selectedImageFile ? (
      <img
        src={`${editedImageName}`}
        alt="Campaign"
        style={{ width: "100%", marginTop: "10px" }}
      />
    ) : selectedImageFile ? (
      <img
        src={URL.createObjectURL(selectedImageFile)}
        alt="Selected"
        style={{ width: "100%", marginTop: "10px" }}
      />
    ) : null}

    {/* Image Upload */}
    <input
      type="file"
      accept="image/*"
      onChange={handleImageChange1}
      style={{ marginTop: "20px" }}
    />
  </DialogContent>
  <DialogActions>
    <MDButton
      onClick={() => setEditModalOpen(false)}
      variant="black1"
      style={{ color: "black" }}
    >
      Cancel
    </MDButton>
    <MDButton
      onClick={handleEditSave}
      variant="gradient"
      disabled={saving1}
      color="dark"
    >
      {saving1
        ? translations.buttons_and_common_words?.saving
        : translations.buttons_and_common_words?.save}
    </MDButton>
  </DialogActions>
</Dialog>


      {/* Delete Confirmation Modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            {loading ? <CircularProgress size={24} /> : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ComposeMessage;

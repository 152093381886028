const portuguese = {
  main_menu: {
    home: "Iniciar",
    subscribers: "Seu Público",
    leads: "Leads e Vips",
    automatize: "Automatizar",
    flowbuilder: "Flowbuilder",
    your_data: "Seus Dados",
    ai_assistant: "Assistente IA",
    settings: "Configurações",
    upload_image: "Enviar Imagem",
    messages_bots: "Bots de Mensagens",
    bot_reports: "Relatórios de Bots",
    bot_birthday: "Bot de Aniversário",
    bot_welcome: "Bot de Boas-Vindas",
    bot_appointment: "Bot de Agendamento",
    bot_firstpurchase: "Bot de Primeira Compra",
    bot_expiring: "Bot de Vencimento",
    campaigns: "Campanhas",
    campaigns_reports: "Relatórios de Campanhas",
    create_new: "Criar Novo",
    integrations: "Integrações",
    our_api: "Nossa API",
    hotmart: "Hotmart",
    google_sheets: "Google Sheets",
    woo_commerce: "Woo Commerce",
    instagram_bot: "Bot do Instagram",
    cupons: "Cupons",
    new_promotion: "Nova Promoção",
    cupons_list: "Lista de Cupons",
    alert_messages: "Mensagens de Alerta",
    whatsapp_cloudapi: "API Cloud do WhatsApp",
    metrics: "Métricas",
    others: "Outros",
    staff: "Equipe",
    lottery: "Sorteio",
  },
  buttons_and_common_words: {
    save: "Salvar",
    delete: "Apagar",
    edit: "Editar",
    upload: "Upload",
    upload_images: "Selecionar Imagens",
    download: "Baixar",
    select: "Selecionar",
    select_file: "Selecionar Arquivo",
    logout: "Sair",
    login: "Entrar",
    signin: "Registrar",
    view: "Visualizar",
    details: "Detalhes",
    export: "Exportar",
    import: "Importar",
    settings: "Configurações",
    send: "Enviar",
    message: "Mensagem",
    image: "Imagem",
    tag: "Tag",
    tags: "Criar Entiquetas",
    actions: "Ações",
    back: "Voltar",
    enteries_per_page: "linhas por página",
    showing: "Mostrando",
    to: "a",
    entries: "linhas",
    saving:"Salvando...",
    are_you_sure:"Tem certeza?",
    ok:"OK",
    cancel:"Cancelar",
    export:"Exportar",
    import:"Importar",
  },
  page_home: {
    total_users: "Total de cadastros",
    than_lastweek: "que semana passada",
    birthday_thismonth: "Aniversários este mês",
    than_lastmonth: "que mês passado",
    than_yesterday: "que ontem",
    automation_is: "Automação está",
    aiassistant_is: "Assistente IA está",
    plans_and_invoices: "Planos e Boletos",
    connect_to_whatsapp: "Conectar ao WhatsApp",
    connected_to_whatsapp: "CONECTADO",
    update_connection_status: "Atualizar Conexão",
    send_test_message: "Enviar Mensagem Teste",
    new_qrcode: "Gerar Novo QRCode",
    automation_restart_timer: "Tempo de reinício da automação",
    timer: "Intervalo (h)",
    your_logo: "Sua Logo e Imagens",
    google_pixel: "Google Pixel",
    meta_pixel: "Meta Pixel",
    terms_of_use: "Termos de Uso",
    upload_logo_images:"clique na caixa para fazer upload da imagem",
    birthday_today:"Aniversarios hoje",
    read_qr:"Ler QRCode",
    whatsapp_connection:"Conectar Whatsapp a Plataforma",
    automation_status:"Status da Automação",
    automation_multichanel_details : "Detalhes da Automação e Multiatendimetno",
    multichanel_server:" Servidor de Multiatendimento",
    multichanel_token : "Token de Multiatendimento"

  },
  page_subscribers: {
    subscribers_table: "Tabela de assinantes",
    your_public: "Seu Público",
    name: "Nome",
    mobile: "Celular",
    dob: "Aniversário",
    company: "Empresa",
    lead: "Lead",
    created_at: "Criado",
    updated_at: "Atualizado",
    actions: "Ações",
    click_up_image: "Selecionar imagem",
    search_bytag: "Selecionar por Etiqueta",
    select_tag: "Selecionar Etiqueta",
    filter_bybirthday: "Filtrar por Aniversário",
    meta_custom_public: "Meta - Público Customizado",
    add_new_subscriber: "Adicionar novo Assinante",
  },
  edit_subscribers: {
    fullname: "Nome Completo",
    firstname: "Primeiro Nome",
    email: "Email",
    address: "Endereço",
    address_number: "Número",
    address_complement: "Complemento",
    district: "Bairro",
    city: "Cidade",
    state: "Estado",
    country: "País",
    zip: "CEP",
    tags: "Etiqueta",
    add_tag: "Adicionar Etiqueta",
    responsable: "Responsável",
    product: "Produto",
    value: "Valor",
    total_visits: "Número de Visitas",
    extra1: "Extra1",
    extra2: "Extra2",
    last_purchase_date: "Data Última Compra",
    expiring_date: "Vencimento",
    legal_id:"CPF",
    gender:"Gender"
  },
  additional_information_about_subscribers: {
    responsable: "Responsável",
    product: "Produto",
    value: "Valor",
    total_visits: "Total de Visitas",
    extra1: "Extra1",
    extra2: "Extra2",
    last_purchase_date: "Data Última Compra",
    expiring_date: "Data de Vencimento",
  },
  page_leads_and_vips: {
    latest_subscribers: "Últimos Assinantes",
  },
  your_data: {
    question:"Question",
    content:"Reply"
  },
  flowbuilder: {
    open_flowbuilder: "Abrir Construtor de Fluxo (Flowbuilder)",
  },
  tags:{
    move_users:"Mover Assinantes"
      },
  bots_reports: {
    ready_tosend: "Gerar Mais Mensagens",
    in_queue: "Na fila para os próximos dias",
    date: "Data",
    name: "Nome",
    message: "Mensagem",
    image: "Imagem",
    tags: "Tags",
    bot_reports: "Relatórios de Bots",
    birthday:"Aniversário",
    type:"Tipo",
    welcome:"Boas-Vindas",
    appointment:"Agendamento",
event_date:"Data do evento",

  },
  settings: {
    set_instructions: "Configurar Nome e Instruções da Assistente IA",
    assistant_name: "Nome da Assistente",
    assistant_instructions: "Instruções (Personalidade e Comportamento)",
    copy_default_prompt: "Copiar modelo de Prompt",
    send_training_file: "Enviar Arquivo de Treinamento",
    number_uploaded_files: "Número de Arquivos Enviados",
    click_to_upload_txt_file: "Clique para Enviar Arquivo TXT",
    list_delete_files: "Listar e Apagar Arquivos TXT",
    test_ai_using_file: "Testar a Assistente Usando Arquivo TXT",
    greetings_message: "Mensagem de Saudação",
  },
  birthday_bot: {
    birthday_bot: "Bot de Aniversário",
    antecipation_in_days: "Quantos dias ANTES quer enviar a mensagem",
    type_message_choose_image: "Digite uma mensagem e anexe uma imagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  welcome_bot: {
    welcome_bot: "Bot de Boas-Vindas (novo cliente)",
    delay_in_days: "Quantos dias DEPOIS quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  firstpurchase_bot: {
    firstpurchase_bot: "Bot de Primeira Compra",
    delay_in_days: "Quantos dias DEPOIS quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  expiring_bot: {
    expiring_bot: "Bot de Vencimento",
    antecipation_in_days: "Quantos dias ANTES quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  appointment_bot: {
    appointment_bot: "Bot de Confirmação de Agendamento",
    antecipation_in_days: "Quantos dias ANTES quer enviar a mensagem",
    placeholders:
      "Placeholders: %nome%, %celular%, %email%, %aniversario%, %tagid%, %empresa%, %endereco%, %numero%, %complemento%, %distrito%, %cidade%, %estado%, %cep%, %datacompra%, %datavencimento%, %operadora%, %produto%, %valor%, %extra1%, %extra2%, %extra3%",
  },
  our_api: {
    generate_token: "Gerar Token",
    get_token: "GERAR",
    save_profile_api: "API de Salvar Perfil",
    api_url: "URL da API",
    copy_url: "Copiar URL",
    actions_api: "API de Ações (Automação)",
    copy_token: "Copier",
  },
  campaign_reports: {
    ready_tosend: "Prontas para Enviar",
    in_queue: "Na Fila para Próximos Dias",
    daily_limit: "Limite Diário",
    web_delivery: "Entrega pela Web",
    web_integrator_message:
      "Ative para enviar as campanhas pela automação WEB. Para mais de 100 envios por dia, use nosso programa disparador para Windows.",
    enter_limit: "Digite o Limite Diário",
    campaign_report_detail:"Detalhes da Campanha",
    loading:"Carregando...",
    mesages30:"+ 30 mensagens",
    ready:"Pronta",
    sent:"Enviada",
    in_queue:"Na fila",
  },
  bots_report: {
    ready_tosend: "Prontas para enviar",
    in_queue: "Na fila para próximos dias",
    date: "Data",
    name: "Nome",
    message: "Mensagem",
    imagem: "Imagem",
    tags: "Tags",
  },
  create_tag: {
    create_tag: " Criar Etiqueta",
    tag_name: "Nome da Etiqueta",
  },
  your_public_import: {
    name: "nome",
    mobile: "celular",
    email: "email",
    dob: "aniversario",
    tagid: "tagid",
    company: "empresa",
    address: "endereco",
    number: "numero",
    complement: "complemento",
    district: "bairro",
    city: "cidade",
    state: "estado",
    cep: "cep",
    cpf: "taxid",
    datepurchase: "datacompra",
    dateexpiring: "datavencimento",
    responsable: "responsavel",
    product: "produto",
    value: "valor",
    extra1: "extra1",
    extra2: "extra2",
  },
  compose_message: {
    compose_message: "Compor Mensagem",
    name_campaign: "Nome da Mensagem",
    enter_text: "Digite um texto",
    message_saved:"Nova mensagem para Campanha salva com sucesso",
    message_name:"Nome da Mensagem"
  },
  tag:{
    total_subs:"Total de Assinantes"
  },
  signin:{
    name_password_signin:"Entre com seu usuário e senha para logar",
    user:"Usuário",
    password:"Senha",
    remember_me:"Lembre de mim",
    signin:"ENTRAR"
  },
  forms_survey:{
    forms_survey:"Formulários e Pesquisa",
  }, 
  generate_message:{
    generate_message: "Gerar Campanha",
    select_message:"Selecionar Mensagem",
    area_code:"Código de Área",
    area_code_all:"Todos Códigos",
    select_area_code:"Selecionar Códigos",
    generate_campaign_now:"GERAR CAMPANHA AGORA",
  },
  multiChanel:{
    multi_channel:"Multiatendimento",
  }

};

export default portuguese;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CakeIcon from "@mui/icons-material/Cake";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";
import { Checkbox, FormControlLabel } from "@mui/material";

const EditSubscriber = () => {
  const [userData, setUserData] = useState({
    name: "",
    fname: "",
    dob: "",
    mobile: "",
    email: "",
    company: "",
    address: "",
    number: "",
    complement: "",
    district: "",
    city: "",
    state: "",
    zipcode: "",
    operator: "",
    product: "",
    value: "",
    extra1: "",
    extra2: "",
    extra3: "",
    purchase_date: "",
    due_date: "",
  });
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user_id } = useParams();
  const group_id = localStorage.getItem("group_id");
  const [userExtra, setUserExtra] = useState({});

  const navigate = useNavigate();

  const handleClickNavigate = () => {
    navigate("/yourPublic");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://crmstation.net/laravel/public/api/api_get_user_tags",
          { group_id, user_id }
        );

        if (response.data.status === "success") {
          const { user, tags: allTags, userTags, userExtra  } = response.data;
          setUserData(user || {});
          setSelectedTags(
            userTags.map((tag) => ({
              value: tag.tag_id,
              label: allTags.find((t) => t.id === tag.tag_id)?.tag || "",
            }))
          );
          setTags(allTags.map((tag) => ({ value: tag.id, label: tag.tag })));
          setUserExtra(userExtra || {}); // Set userExtra data
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [group_id, user_id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setUserExtra({ ...userExtra, [name]: value });
  };

  const handleTagsChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };

  const formatDateForDisplay = (dob_day, dob_month, dob_year) => {
    if (!dob_day || !dob_month || !dob_year) return "";
    return `${dob_year}-${dob_month.padStart(2, "0")}-${dob_day.padStart(2, "0")}`;
  };

  const formatDateForAPI = (dateStr) => {
    if (!dateStr) return "";
    const [year, month, day] = dateStr.split("-");
    return `${month}-${day}-${year}`;
  };
  
  const handleSave = () => {
    setLoading(true);
    const dataToSave = {
      ...userData,
      ...userExtra,
      tags: JSON.stringify(selectedTags.map((tag) => tag.value)),
      user_id: user_id,
      group_id: group_id,
      dob: formatDateForAPI(userData.dob),
      purchase_date: formatDateForAPI(userData.purchase_date),
      due_date: formatDateForAPI(userData.due_date),
    };
  
    fetch("https://crmstation.net/laravel/public/api/api_update_user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSave),
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        toast.success("Data Saved Successfully");
      })
      .catch((error) => {
        setLoading(false);
        toast.error("An error occurred!");
        console.error("Error saving data", error);
      });
  };
  

  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Card>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={12}>
              <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                Edit Subscribers
              </MDTypography>
              <MDBox px={3} py={2}>
                <Grid container spacing={2}>
                  {[
                    {
                      label: translations.edit_subscribers?.fullname,
                      name: "name",
                    },
                    {
                      label: translations.edit_subscribers?.firstname,
                      name: "fname",
                    },
                    {
                      label: "Birthday",
                      name: "dob",
                      type: "date",
                      value: formatDateForDisplay(
                        userData.dob_day,
                        userData.dob_month,
                        userData.dob_year
                      ),
                      onChange: (e) => {
                        const [year, month, day] = e.target.value.split("-");
                        setUserData({
                          ...userData,
                          dob_day: day,
                          dob_month: month,
                          dob_year: year,
                          dob: e.target.value,
                        });
                      },
                      InputProps: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <CakeIcon />
                          </InputAdornment>
                        ),
                      },
                    },
                    { label: "Cell Phone", name: "mobile" },
                    {
                      label: translations.edit_subscribers?.email,
                      name: "email",
                    },
                    { label: "Company", name: "company" },
                    {
                      label: translations.edit_subscribers?.address,
                      name: "address",
                    },
                    {
                      label: translations.edit_subscribers?.address_number,
                      name: "number",
                    },
                    {
                      label: translations.edit_subscribers?.address_complement,
                      name: "complement",
                    },
                    {
                      label: translations.edit_subscribers?.district,
                      name: "district",
                    },
                    {
                      label: translations.edit_subscribers?.city,
                      name: "city",
                    },
                    {
                      label: translations.edit_subscribers?.state,
                      name: "state",
                    },
                    {
                      label: translations.edit_subscribers?.zip,
                      name: "zipcode",
                    },
                    {
                      label: translations.edit_subscribers?.legal_id,
                      name: "legal_id",
                    },
                    {
                      label: translations.edit_subscribers?.gender,
                      name: "gender",
                    },
                  ].map((field, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <TextField
                        fullWidth
                        label={field.label}
                        name={field.name}
                        value={field.value || userData[field.name] || ""}
                        onChange={field.onChange || handleInputChange}
                        variant="outlined"
                        type={field.type || "text"}
                        InputProps={field.InputProps}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Divider sx={{ my: 3 }} />
                <Grid container spacing={2}>
  {[
    {
      label: translations.edit_subscribers?.responsable,
      name: "operator",
    },
    {
      label: translations.edit_subscribers?.product,
      name: "product",
    },
    {
      label: translations.edit_subscribers?.value,
      name: "expenses",
    },
    {
      label: translations.edit_subscribers?.extra1,
      name: "extra1",
    },
    {
      label: translations.edit_subscribers?.extra2,
      name: "extra2",
    },
    {
      label: translations.edit_subscribers?.last_purchase_date,
      name: "purchase_date",
      type: "date",
      value: userData.purchase_date,
      onChange: (e) =>
        setUserExtra({ ...userExtra,
          purchase_date: e.target.value,
        }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <CakeIcon />
          </InputAdornment>
        ),
      },
    },
    {
      label: translations.edit_subscribers?.expiring_date,
      name: "due_date",
      type: "date",
      value: userData.due_date,
      onChange: (e) =>
        setUserExtra({ ...userExtra, due_date: e.target.value }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <CakeIcon />
          </InputAdornment>
        ),
      },
    },
  ].map((field, index) => (
    <Grid item xs={12} md={6} key={index}>
      <TextField
        fullWidth
        label={field.label}
        name={field.name}
        value={field.value || userExtra[field.name] || ""}
        onChange={field.onChange || handleInputChange1}
        variant="outlined"
        type={field.type || "text"}
        InputProps={field.InputProps}
      />
    </Grid>
  ))}
  {/* Add is_lead checkbox */}
  <Grid item xs={12} md={6}>
    <FormControlLabel
      control={
        <Checkbox
          checked={userExtra.is_lead || false}
          onChange={(e) =>
            setUserExtra({ ...userExtra,is_lead: e.target.checked })
          }
        />
      }
      label={
        <>
          {translations.edit_subscribers?.is_lead || "Lead"}
          <Typography variant="body2" color="textSecondary" style={{ marginLeft: 8 }}>
            {translations.edit_subscribers?.is_lead_description}
          </Typography>
        </>
      }
    />
  </Grid>
  {/* Add comments text field */}
  <Grid item xs={12} md={6} lg={12}>
    <TextField
      fullWidth
      label={translations.edit_subscribers?.comments}
      name="comments"
      value={userExtra.comments || ""}
      onChange={(e) => setUserExtra({ ...userExtra, comments: e.target.value })}
      variant="outlined"
      multiline
      rows={4}
    />
  </Grid>
  <Grid item xs={12} md={6} lg={12}>
    <Select
      placeholder={translations.edit_subscribers?.tags}
      value={selectedTags}
      options={tags}
      onChange={handleTagsChange}
      isMulti
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#e0e0e0",
          primary: "#333333",
        },
      })}
    />
  </Grid>
</Grid>

                <MDBox mt={3} display="flex" justifyContent="flex-end" gap={2}>
                  <MDButton
                    variant="gradient"
                    color="black1"
                    onClick={handleClickNavigate}
                    style={{ color: "black" }}
                  >
                    {translations.buttons_and_common_words?.back}
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleSave}
                    disabled={loading}
                  >
                    {loading
                      ? "Saving..."
                      : translations.buttons_and_common_words?.save}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditSubscriber;

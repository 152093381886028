import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Grid from "@mui/material/Grid";
import MDBox from "../../../components/MDBox";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";
import API_ROUTES from "../../../config/apiConfig";
import { useLanguageState } from "../../../context/LanguageContext";
import loadTranslations from "../../../utils/loadTranslations";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import UserExportButton from "./UserExportButton";

const YourData = () => {
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
  });
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [noData, setNoData] = useState(false);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
    };

    fetchTranslations();
  }, [language]);

  useEffect(() => {
    if (translations) {
      setTableData((prevState) => {
        const newColumns = [
          {
            Header: translations.page_subscribers?.created_at || "Created Date",
            accessor: "created_at",
            width: "20%",
          },
         
          {
            Header: translations.your_public_import?.mobile,
            accessor: "number",
            width: "25%",
          },
          {
            Header: translations.your_data?.question,
            accessor: "variable_name",
            width: "25%",
          },
          {
            Header: translations.your_data?.content,
            accessor: "content",
            width: "25%",
          },
          {
            Header: translations.page_subscribers?.actions || "Actions",
            accessor: "actions",
            width: "15%",
          },
        ];

        return { ...prevState, columns: newColumns };
      });
    }
  }, [translations]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(false);
      setNoData(false);

      try {
        const group_id = localStorage.getItem("group_id");
        const response = await axios.post(API_ROUTES.YOUR_DATA_FETCH_DATA, {
          group_id,
        });

        const fetchedData = response.data.data.map((item) => ({
          created_at: formatDate(item.created_at),
          content: item.content,
          variable_name: item.fieldname,
          number:item.number,
          actions: (
            <IconButton
              color="secondary"
              aria-label="delete"
              style={{ color: "#f72585" }}
            >
              <DeleteIcon />
            </IconButton>
          ),
        }));

        if (fetchedData.length > 0) {
          setTableData((prevState) => ({
            ...prevState,
            rows: fetchedData,
          }));
        } else {
          setNoData(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    if (translations) {
      fetchData();
    }
  }, [translations]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  if (loading) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h6" color="error">
              Something went wrong
            </Typography>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (noData) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h6">No data found</Typography>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">
          {translations.main_menu?.your_data}
        </Typography>
        <UserExportButton /> {/* Export Button Added Here */}
      </Grid>
        <Grid container>
          <MDBox mt={3} width="100%">
            <DataTable table={tableData} canSearch />
          </MDBox>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default YourData;

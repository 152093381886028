import React, { useState, useEffect } from "react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import MDBox from "../../components/MDBox";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import SimpleCard from "./SimpleCard";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MDButton from "../../components/MDButton";
import loadTranslations from "../../utils/loadTranslations";
import { useLanguageState } from "../../context/LanguageContext";
import avatarUrl from "../../assets/images/dummy.jpg";

const Leads = () => {
  const { language } = useLanguageState();
  const [translations, setTranslations] = useState({});
  const [state, setState] = useState({
    columns: {
      "column-1": {
        id: "column-1",
        title: "", // Initialize with an empty string
        cardIds: [],
      },
      "column-2": {
        id: "column-2",
        // title: "In Progress",
        cardIds: [],
      },
      "column-3": {
        id: "column-3",
        // title: "In Revziew",
        cardIds: [],
      },
    },
    cards: {},
    columnOrder: ["column-1", "column-2", "column-3"],
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleOpenModal = (card) => {
    setModalContent(card);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalContent(null);
  };

  const handleSave = () => {
    // Handle save logic here
    console.log("Saved", modalContent);
    setModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setModalContent((prevContent) => ({
      ...prevContent,
      [name]: value,
    }));
  };

  const [groupId, setGroupId] = useState(localStorage.getItem("group_id"));

  useEffect(() => {
    // Monitor changes to group_id and update state
    const handleStorageChange = () => {
      setGroupId(localStorage.getItem("group_id"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const fetchTranslations = async () => {
      const loadedTranslations = await loadTranslations(language);
      setTranslations(loadedTranslations);
      // Update the state with the translations
      setState((prevState) => ({
        ...prevState,
        columns: {
          ...prevState.columns,
          "column-1": {
            ...prevState.columns["column-1"],
            title: loadedTranslations.page_leads_and_vips?.latest_subscribers,
          },
        },
      }));
    };

    fetchTranslations();
  }, [language]);
  // const group_id = localStorage.getItem("group_id");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `https://crmstation.net/laravel/public/api/api_users_20_by_group?group_id=${groupId}`
        );
        if (response.data.status === "success") {
          const fetchedCards = response.data.data.reduce((acc, item) => {
            const createdAt = item.updated_at
              ? new Date(item.updated_at).toLocaleDateString("en-GB")
              : "Date not found";

            let name = item.name;
            let email = item.mobile;

            // Display ID in either name or email, not both
            if (!name && !email) {
              name = `ID: ${item.user_id}`;
            } else if (!name) {
              name = `ID: ${item.user_id}`;
            } else if (!email) {
              email = `ID: ${item.user_id}`;
            }

            acc[item.user_id] = {
              id: item.user_id.toString(),
              name: name,
              email: email,
              createdAt: createdAt,
            };
            return acc;
          }, {});

          // Sort cards by date in descending order
          // Sort all cards by date in descending order
          const sortedCardIds = Object.keys(fetchedCards).sort((a, b) => {
            const dateA = new Date(fetchedCards[a].createdAt);
            const dateB = new Date(fetchedCards[b].createdAt);
            return dateB - dateA; // Descending order
          });

          setState((prevState) => ({
            ...prevState,
            cards: fetchedCards,
            columns: {
              ...prevState.columns,
              "column-1": {
                ...prevState.columns["column-1"],
                cardIds: sortedCardIds,
              },
            },
          }));
        } else {
          setError("Something went wrong");
        }
      } catch (error) {
        setError("Something went wrong");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [groupId]); // Fetch data whenever groupId changes

  const onDragEnd = (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (type === "column") {
      const newColumnOrder = Array.from(state.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      };
      setState(newState);
      return;
    }

    const start = state.columns[source.droppableId];
    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newCardIds = Array.from(start.cardIds);
      newCardIds.splice(source.index, 1);
      newCardIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        cardIds: newCardIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn,
        },
      };

      setState(newState);
      return;
    }

    const startCardIds = Array.from(start.cardIds);
    startCardIds.splice(source.index, 1);
    const newStart = {
      ...start,
      cardIds: startCardIds,
    };

    const finishCardIds = Array.from(finish.cardIds);
    finishCardIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      cardIds: finishCardIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    };

    setState(newState);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="all-columns"
              direction="horizontal"
              type="column"
            >
              {(provided) => (
                <Grid
                  container
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  spacing={3}
                >
                  {state.columnOrder.map((columnId, index) => {
                    const column = state.columns[columnId];
                    const cards = column.cardIds.map(
                      (cardId) => state.cards[cardId]
                    );

                    return (
                      <Draggable
                        key={column.id}
                        draggableId={column.id}
                        index={index}
                      >
                        {(provided) => (
                          <Grid
                            item
                            xs={4}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                          >
                            <MDBox {...provided.dragHandleProps} mt={3} mb={3}>
                              <h3>{column.title}</h3>
                              <Droppable droppableId={column.id} type="card">
                                {(provided) => (
                                  <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                  >
                                    {cards.map((card, index) => (
                                      <Draggable
                                        key={card.id}
                                        draggableId={card.id}
                                        index={index}
                                      >
                                        {(provided) => (
                                          <MDBox
                                            mt={3}
                                            mb={3}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            onDoubleClick={() =>
                                              handleOpenModal(card)
                                            }
                                          >
                                            <SimpleCard
                                              title={card.name}
                                              email={card.email}
                                              createdAt={card.createdAt}
                                              avatarUrl={avatarUrl}
                                            />
                                          </MDBox>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </MDBox>
                          </Grid>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </MDBox>
      <Footer />

      <Modal open={modalOpen} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={modalOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: "10px",
              border: "none",
            }}
          >
            {modalContent && (
              <>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  name="name"
                  value={modalContent.name}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  name="email"
                  value={modalContent.email}
                  onChange={handleChange}
                />
                <TextField
                  fullWidth
                  label="Date"
                  variant="outlined"
                  margin="normal"
                  name="createdAt"
                  value={modalContent.createdAt}
                  onChange={handleChange}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: 2,
                  }}
                >
                  <MDButton
                    onClick={handleCloseModal}
                    variant="gradient"
                    color="black1"
                    style={{ color: "black" }}
                  >
                    Close
                  </MDButton>
                  <MDButton
                    onClick={handleSave}
                    variant="gradient"
                    color="dark"
                  >
                    {translations.buttons_and_common_words?.save}
                  </MDButton>
                </Box>
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </DashboardLayout>
  );
};

export default Leads;
